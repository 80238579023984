import React from 'react'

function BannerBase({children, classes}) {
    return (
        <div className={classes}>
            {children}
        </div>
    )
}

export default BannerBase;
