import React, { useState } from "react";
import BannerBase from "../BannerBase";
import carouselImgOne from "../../../assets/img/banner-home.png";
import './styles.css';

function BannerHome({src}) {
  return (
    <BannerBase classes={"banner-img-full"}>
      <img className={'banner-img-full'} src={src ? src : carouselImgOne} alt="netflix-carousel"></img>
    </BannerBase>
  );
}

export default BannerHome;
