import React from "react";
import "./styles.css";

import twitterIcon from "../../../assets/img/twitter.svg";
import facebookIcon from "../../../assets/img/facebook.svg";
import instagramIcon from "../../../assets/img/instagram.svg";
import teljoyLogoGreen from "../../../assets/img/teljoy-logo-green.svg";

import chevron from "../../../assets/img/chevron-white.svg";

function FooterContainer() {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div className={"home-btm-links"}>
        <p>Shop</p>
        <p>Company</p>
        <p>Account</p>
        <p>Help/FAQ's</p>
      </div>
      <div id="search-main" style={{ padding: 0, marginBottom: "24px" }}>
        <div className={"searchbar flex flex-row jc-c ai-c"}>
          <input
            type="text"
            name="letter_email"
            placeholder={"Sign up for our newsletter "}
          ></input>
          <div className={"searchbar-icon flex jc-c ai-c"}>
            <img src={chevron} alt={"chevron"}></img>
          </div>
        </div>
      </div>
      <div className={"share-icon-row"}>
        <img src={twitterIcon}></img>
        <img src={facebookIcon}></img>
        <img src={instagramIcon}></img>
      </div>
      <div style={{ textAlign: "center", marginBottom: "10px" }}>
        <img src={teljoyLogoGreen}></img>
      </div>
      <div id="footer-container" className={"flex flex-col jc-fs ai-c"}>
        <p>Privacy Policy</p>
        <p>Terms and Conditions</p>
        <p>Disclaimer</p>
        <p>PAIA</p>
        <p>SA COVID 19</p>
      </div>
      <p
        style={{
          textAlign: "center",
          color: "var(--color-primary)",
          margin: "14px 0",
        }}
      >
        Copyright, Teljoy 2020. All Rights Reserved{" "}
      </p>
    </div>
  );
}

export default FooterContainer;
