import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import Home from "./components/pages/Home";
import Subcategory from "./components/pages/Subcategory";
import Category from "./components/pages/Category";
import Product from "./components/pages/Product";
import Cart from "./components/pages/Cart";
import MainContainer from "./components/containers/MainContainer";
import NavContainer from "./components/containers/NavContainer";
import ContentContainer from "./components/containers/ContentContainer";
import AppContext from "./context/AppContext";

function App() {
  const [state, setState] = React.useState({
    cart: [],
    nav: {
      heading: null,
      subheading: null,
      purchaseType: "rto",
    },
  });
  return (
    <AppContext.Provider value={{ state, setState }}>
      <MainContainer>
        <Router>
          <NavContainer></NavContainer>
          <ContentContainer>
            <div style={{ height: "100%", width: "100%" }}>
              <Switch>
                <Route path="/cart">
                  <Cart />
                </Route>
                <Route path="/:category/:subcategory/:product_slug">
                  <Product />
                </Route>
                <Route path="/:category/:subcategory">
                  <Subcategory />
                </Route>
                <Route path="/:category">
                  <Category />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
            </div>
          </ContentContainer>
        </Router>
      </MainContainer>
    </AppContext.Provider>
  );
}

export default App;
