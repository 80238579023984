import React, { useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as DUMMY_PRODUCTS from "../../../dummy/dummy.data";
import Product from "../../products/Product";
import Searchbar from "../../search/Searchbar";
import "./styles.css";

import filterIcon from "../../../assets/img/filter-icon.svg";
import FooterContainer from "../../containers/FooterContainer";
import AppContext from "../../../context/AppContext";

import chevronIcon from "../../../assets/img/chevron-right.svg";

import { Slider } from "@material-ui/core";

import { HEADINGS_CATEGORIES } from "../../../dummy/headings.data";

const FilterModal = ({
  active,
  onCloseClick,
  priceRange,
  setPriceRange,
  brand,
  brandList,
  setBrand,
  category,
}) => {
  const { state } = useContext(AppContext);
  const [brandActive, setBrandActive] = useState(false);
  const [priceActive, setPriceActive] = useState(false);
  const [headings] = useState(category());

  return (
    <div
      className={`category-filter ${
        active ? "active" : ""
      } text-calibre flex flex-col jc-se`}
      style={{ padding: 18 }}
    >
      {true ? (
        <>
          <div className={"flex flex-row jc-sb ai-c"}>
            <h2 style={{ textDecoration: "underline" }}>Filter</h2>
            <h2 onClick={onCloseClick}>X</h2>
          </div>
          <div>
            <h4>Categories</h4>
            <h5 style={{ whiteSpace: "pre" }}>
              {"    "}
              {headings.heading}
            </h5>
            <h5 style={{ whiteSpace: "pre" }}>
              {"        "}
              {headings.subheading}
            </h5>
          </div>
          <div>
            <div
              className={"flex flex-row jc-sb ai-c"}
              style={{ fontSize: "20px" }}
              onClick={() => setBrandActive(!brandActive)}
            >
              <h4>Brands</h4>
              <img
                src={chevronIcon}
                className={"rot-90"}
                style={{ width: "14px", height: "14px" }}
              ></img>
            </div>
            <div
              className={`filter-section ${
                priceActive ? "open" : ""
              } flex flex-row jc-fs ai-c flex-w w-100`}
            >
              {brandList.map((filter) => (
                <div
                  className={`filter-img-container flex ai-c jc-c ${
                    filter.name === brand ? "selected" : ""
                  }`}
                  onClick={() => setBrand(filter.name)}
                >
                  <img
                    className={`filter-img`}
                    src={filter.img}
                    style={{ margin: "8px" }}
                    alt={filter.name}
                  />
                </div>
              ))}
            </div>
          </div>
          <div>
            <div
              className={"flex flex-row jc-sb ai-c"}
              onClick={() => setPriceActive(!priceActive)}
            >
              <h4 style={{ fontSize: 20 }}>Price</h4>
              <img
                src={chevronIcon}
                className={"rot-90"}
                style={{ width: "14px", height: "14px" }}
              ></img>
            </div>
            <div className={`filter-section ${priceActive ? "open" : ""}`}>
              <Slider
                value={priceRange}
                onChange={(e, v) => setPriceRange(v)}
                step={100}
                min={1}
                max={25000}
                valueLabelDisplay="off"
                style={{ color: "var(--color-primary)" }}
              />
              <div className={"flex flex-row jc-sb ai-c"}>
                <p>
                  Min: R {priceRange[0]}{" "}
                  {state.nav.purchaseType === "rto" ? "per month" : ""}
                </p>
                <p>
                  Max: R {priceRange[1]}{" "}
                  {state.nav.purchaseType === "rto" ? "per month" : ""}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

function Subcategory() {
  const { state, setState } = useContext(AppContext);
  const { category, subcategory } = useParams();
  const [currIndex, setCurrIndex] = useState(-1);
  const [newDem, setNewDemo] = useState("rto");
  const [priceRange, setPriceRange] = useState([0, 25000]);
  const [brand, setBrand] = useState(null);
  const [isFilterModalActive, setIsFilterModalActive] = useState(false);
  const history = useHistory();
  const openProduct = (slug) => {
    const curr = window.location.pathname;
    history.push(`${curr}/${slug}`);
  };

  // useEffect(() => {
  //   if (!headings.heading) {
  //     getHeadings();
  //   }
  // }, [headings])

  const getHeadings = () => {
    const heading = HEADINGS_CATEGORIES.find((item) => item.slug === category);
    const subheading = heading.sublinks.find(
      (item) => item.slug === subcategory
    );
    return { heading: heading.name, subheading: subheading.name };
  };

  const handleSetBrand = (b) => {
    if (brand === b) {
      setBrand(null);
    } else {
      setBrand(b);
    }
  };

  const setPurchaseType = (type) => {
    let s = { ...state };
    s.nav.purchaseType = type;
    setNewDemo(type);
    setState(s);
  };

  const getDummyData = () => {
    let cat;
    switch (category) {
      case "appliances":
        cat = DUMMY_PRODUCTS.APPLIANCES_DUMMY;
        break;
      case "electronics":
        cat = DUMMY_PRODUCTS.ELECTRONICS_DUMMY;
        break;
      case "furniture":
        cat = DUMMY_PRODUCTS.FURNITURE_DUMMY;
        break;
      case "gym-equipment":
        cat = DUMMY_PRODUCTS.GYM_DUMMY;
        break;
      default:
        cat = null;
        break;
    }
    if (!cat) return [];
    const filtered = filter(cat.find((item) => item.slug === subcategory));
    return filtered && filtered.length ? filtered : [];
  };

  const getBrandsList = () => {
    let cat;
    switch (category) {
      case "appliances":
        cat = DUMMY_PRODUCTS.APPLIANCES_DUMMY;
        break;
      case "electronics":
        cat = DUMMY_PRODUCTS.ELECTRONICS_DUMMY;
        break;
      case "furniture":
        cat = DUMMY_PRODUCTS.FURNITURE_DUMMY;
        break;
      case "gym-equipment":
        cat = DUMMY_PRODUCTS.GYM_DUMMY;
        break;
      default:
        cat = null;
        break;
    }
    if (!cat) return [];
    const filtered = cat.find((item) => item.slug === subcategory);
    return filtered ? filtered.brands : [];
  };

  const filter = (data) => {
    if (!data || data.length === 0) return [];
    const resp = data.products.filter(
      (item) => item.price >= priceRange[0] && item.price <= priceRange[1]
    );
    if (brand) {
      resp.filter((item) => item.brand === brand);
    }
    return resp;
  };

  return (
    <div id="category">
      <FilterModal
        active={isFilterModalActive}
        onCloseClick={() => setIsFilterModalActive(false)}
        priceRange={priceRange}
        setPriceRange={setPriceRange}
        brand={brand}
        brandList={getBrandsList()}
        setBrand={handleSetBrand}
        category={getHeadings}
      ></FilterModal>
      <div className={"category-search"}>
        <Searchbar></Searchbar>
        <div
          onClick={() => setIsFilterModalActive(true)}
          className={"nav-filter-icon flex jc-c ai-c"}
        >
          <img src={filterIcon} style={{ width: "20px", height: "20px" }}></img>
        </div>
      </div>
      <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
        <div className="text-center color-text-other mv">
          <h3 className={"text-calibre"}>{getHeadings().heading} /</h3>
          <h2 className={"text-barlow"} style={{ letterSpacing: 1 }}>
            {getHeadings().subheading}
          </h2>
        </div>
        <div className="flex flex-row jc-fs flex-w">
          {getDummyData().map((product, index) => {
            if (brand) {
              if (product.brand === brand) {
                return (
                  <Product
                    product={product}
                    onClick={() => openProduct(product.slug)}
                    active={false}
                  ></Product>
                );
              }
            } else {
              return (
                <Product
                  product={product}
                  onClick={() => openProduct(product.slug)}
                  active={false}
                ></Product>
              );
            }
          })}
        </div>
        <FooterContainer></FooterContainer>
      </div>

      {currIndex === -1 ? (
        <div id="category-new-demo-toggle">
          <div>
            <p
              onClick={() => setPurchaseType("rto")}
              className={`${newDem === "rto" ? "active" : ""}`}
            >
              New
            </p>
            <p
              onClick={() => setPurchaseType("demo")}
              className={`${newDem === "demo" ? "active" : ""}`}
            >
              Demo
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Subcategory;
