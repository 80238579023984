import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/img/teljoy-logo.svg";
import cartIcon from "../../../assets/img/shopping-cart.svg";
import "./styles.css";
import NavPanel from "../NavPanel";
import AppContext from "../../../context/AppContext";
import Searchbar from '../../search/Searchbar';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const { state, setState } = useContext(AppContext);
  return (
    <div id="nav">
      <div className={"nav-bar flex flex-row jc-fs ai-c"}>
        <Link to='/'>
          <img style={{marginTop: '14px'}} src={logo} alt="teljoy-logo" />
        </Link>
        <div className={"nav-icons flex flex-row jc-fe ai-c"}>
          <Link to="/cart">
            <div id="cart-icon" >
              <img src={cartIcon} alt="cart-icon" />
              <div className={"badge-icon"}>
                <p>{state.cart.length}</p>
              </div>
            </div>
          </Link>
          <div
            onClick={() => setIsOpen(!isOpen)}
            id="menu-icon"
            className={`${isOpen ? "active" : ""}`}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <NavPanel onLinkClick={() => setIsOpen(false)} open={isOpen} />
    </div>
  );
}

export default Navbar;
