import React from "react";
import heartIcon from '../../../assets/img/heart-icon.svg';
import starIcon from '../../../assets/img/star-icon.svg';
import './styles.css';

function ProductCard({ product }) {
  return (
    <div className={`product-card flex flex-col js-se ai-c w-50`}>
      <div
        className={"text-center product-card-img container"}
        style={{ position: "relative" }}
      >
        <img
          style={{ position: "absolute", top: 0, right: 0, margin: "10px" }}
          src={heartIcon}
        ></img>
        <img src={product.img} alt={product.img}></img>
      </div>
      <div className={`flex flex-row jc-se container`}>
        <div
          className={"text-thin text-calibre flex flex-col jc-se"}
        >
          <h3 className={"color-text-primary text-ellipsis"} style={{textOverflow: 'ellipsis'}}>{product.name}</h3>
          <h4 className={"color-text-dim"}>{product.brand}</h4>
          <h3>R{Math.round(product.price / 12, 2)} pm</h3>
        </div>
        <div
          className={"flex flex-col ai-c"}
          style={{ margin: "10px", width: '40px' }}
        >
          <img
            style={{ height: "16px", marginBottom: "3px" }}
            src={starIcon}
          ></img>
          <p style={{ fontSize: "10px", color: 'black' }}>
            4 <span style={{ color: "var(--color-text-dim)" }}>(10)</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
