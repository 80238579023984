import React from "react";
import "./styles.css";

function BannerExpandable({ children, shadowsTop = false }) {
  return (
    <div className={`banner-expandable ${shadowsTop ? 'banner-shadow-top' : ''}`}>
      {children}
      <div className={"read-more"}>
          <p>Read More</p>
      </div>
    </div>
  );
}

export default BannerExpandable;
