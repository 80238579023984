import React, { useContext } from "react";
import AppContext from "../../../context/AppContext";
import heartIcon from '../../../assets/img/heart-icon.svg';
import starIcon from '../../../assets/img/star-icon.svg';

import "./styles.css";

function Product({ product, onClick, active }) {
  const { state, setState } = useContext(AppContext);

  const handleClick = () => {
    // if (!active) {
    //   const obj = {
    //     name: product.name,
    //     product: product,
    //     qty: 1,
    //     extra: {},
    //   };
    //   const c = [...state.cart];
    //   c.push(obj);
    //   setState({ cart: c });
    //   onClick();
    // }
    onClick();
  };

  return (
    <div
      onClick={handleClick}
      className={`product ${active ? "open" : "closed"} flex flex-col w-50`}
    >
      <div className={"text-center product-img container"} style={{position: 'relative'}}>
        <img style={{position: 'absolute', top: 0, right: 0, margin: '10px'}} src={heartIcon}></img>
        <img src={product.img} alt={product.img}></img>
        <div className={'flex flex-col ai-c'} style={{position: 'absolute', bottom: 0, right: 0, margin: '10px'}}>
          <img style={{height: '16px', marginBottom: '3px'}} src={starIcon}></img>
          <p style={{fontSize: '10px'}}>4 <span style={{color: 'var(--color-text-dim)'}}>(10)</span></p>
        </div>
      </div>
      <div className={`flex flex-row jc-sb container`}>
        <div className={"text-thin text-calibre"} style={{paddingRight: '5px'}}>
          <h3 className={"color-text-primary"}>{product.name}</h3>
          <h4 className={"color-text-dim"}>{product.brand}</h4>
          <h3>R{state.nav.purchaseType === 'rto' ? Math.round(product.price / 12, 2) + ' per month' : product.price }</h3>
        </div>
        <div className={`flex ${active ? "flex-row" : "flex-col"} ai-c`}>
          <h6 style={{whiteSpace: 'nowrap', marginBottom: '3px'}}>In Stock</h6>
          {product.stock.map((s) => {
            return <h6 className={"text-bg"}>{s}</h6>;
          })}
        </div>
      </div>
    </div>
  );
}

export default Product;
