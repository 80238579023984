import React, { useContext } from 'react'
import AppContext from '../../../context/AppContext'
import './styles.css';

import fridgeOne from '../../../assets/img/defy_lrg.png';

function CartProduct({product}) {
    const { state, setState } = useContext(AppContext);
    const incQty = () => {
        const s = {...state};
        s.cart.find(item => item.product.slug === product.product.slug).qty++;
        setState(s);
    }
    const decQty = () => {
        const s = {...state};
        s.cart.find(item => item.product.slug === product.product.slug).qty--;
        setState(s);
    }
    return (
        <div className='cart-product flex flex-row'>
            <div className='cp-img flex jc-c ai-c' style={{padding: '2px 8px', width: '25%'}}>
                <img src={product.product.img} style={{margin: '0 6px', maxWidth: '90%', maxHeight: '200px'}}></img>
            </div>
            <div className='cp-info flex flex-col jc-se'>
                <h3 className={'color-text-primary'}>{product.product.name}</h3>
                <h4 className={'color-text-dim'}>{product.product.brand}</h4>
                <div>
                    <h5 className={'color-text-primary'}>Rental Period / Price</h5>
                    <h4>{product.period ? Math.round(product.product.price / product.period, 2) + ' per month' : product.product.price}</h4>
                </div>
                <div>
                    <p className={'color-text-dim'}>Quantity:</p>
                    <div className={'flex flex-row'}>
                        <p onClick={decQty} className={'qty-input-btn'} style={{marginRight: '6px'}}>-</p>
                        <p>{product.qty}</p>
                        <p onClick={incQty} className={'qty-input-btn'} style={{marginLeft: '6px'}}>+</p>
                    </div>
                </div>
                <div className={'flex flex-row ai-c'}>
                    <p>In stock</p>
                    {product.product.stock.map(s => {
                        return <p className={'text-bg'} style={{marginLeft: '8px'}}>{s}</p>
                    })}
                </div>
            </div>
            <div className='cp-icons'></div>
        </div>
    )
}

export default CartProduct
