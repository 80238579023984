import React from "react";
import BannerHome from "../../banners/BannerHome";
import Searchbar from "../../search/Searchbar";
import appCatImg from "../../../assets/img/appliances-category.png";
import elecCatImg from "../../../assets/img/elec-category.png";
import furnCatImg from "../../../assets/img/furniture-category.png";
import gymCatImg from "../../../assets/img/gym-category.png";
import CircleImg from "../../img/CircleImg";
import BannerInfo from "../../banners/BannerInfo";
import secondHomeBanner from "../../../assets/img/banner-second.png";

import rtoIcon from "../../../assets/img/rent-to-own.svg";
import timeIcon from "../../../assets/img/time-icon.svg";
import closeRoundIcon from "../../../assets/img/close-round-icon.svg";
import toolsIcon from "../../../assets/img/tools-icon.svg";
import pcIcon from "../../../assets/img/pc-icon.svg";
import umbrellaIcon from "../../../assets/img/umbrella-icon.svg";
import truckIcon from "../../../assets/img/truck-icon.svg";

import delonghiImg from "../../../assets/img/delonghi.png";

import "./styles.css";
import FooterContainer from "../../containers/FooterContainer";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div>
      <Searchbar></Searchbar>
      <BannerHome></BannerHome>
      <h1 className={"section-heading"}>Categories</h1>
      <div className={"flex flex-row jc-se ai-c flex-w"}>
        <Link to="/appliances">
          <CircleImg src={appCatImg} text={"Appliances"}></CircleImg>
        </Link>
        <Link to={"/electronics"}>
          <CircleImg src={elecCatImg} text={"Electronics"}></CircleImg>
        </Link>
        <Link to={"/furniture"}>
          <CircleImg src={furnCatImg} text={"Furniture"}></CircleImg>
        </Link>
        <Link to={"/gym-equipment"}>
          <CircleImg src={gymCatImg} text={"Gym Equipment"}></CircleImg>
        </Link>
      </div>
      <BannerInfo bgColor={"#26C5AB"}>
        <h1>Why Rent-to-Own</h1>
        <h3>Ownership Options</h3>
        <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo.
        </p>
        <div className={"flex flex-row jc-se ai-c w-100"}>
          <img className={"after-select"} src={rtoIcon} alt="rto"></img>
          <img src={timeIcon} alt="rto"></img>
          <img src={closeRoundIcon} alt="rto"></img>
          <img src={toolsIcon} alt="rto"></img>
          <img src={pcIcon} alt="rto"></img>
          <img src={umbrellaIcon} alt="rto"></img>
          <img src={truckIcon} alt="rto"></img>
        </div>
      </BannerInfo>
      <BannerHome src={secondHomeBanner}></BannerHome>
      <BannerHome src={delonghiImg}></BannerHome>
      <FooterContainer></FooterContainer>
    </div>
  );
}

export default Home;
