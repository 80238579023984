import React from "react";
import NavItem from "../NavItem";

import twitterIcon from "../../../assets/img/twitter.svg";
import facebookIcon from "../../../assets/img/facebook.svg";
import instagramIcon from "../../../assets/img/instagram.svg";

const catLinks = [
  {
    name: "Appliances",
    parentUrl: "/appliances",
    children: [
      {
        to: "/fridges-ice-makers",
        name: "Fridges/Ice",
      },
      {
        to: "/dishwashers",
        name: "Dishwashers",
      },
    ],
  },
  {
    name: "Electronics",
    parentUrl: "/electronics",
    children: [
      {
        to: "/tv",
        name: "TVs",
      },
    ],
  },
  {
    name: "Furniture",
    parentUrl: "/furniture",
    children: [
      {
        to: "/beds-bedroom-suites",
        name: "Beds & Bedrooms",
      },
    ],
  },
  {
    name: "Gym",
    parentUrl: "/gym-equipment",
    children: [
      {
        to: "/treadmills",
        name: "Treadmills",
      },
    ],
  },
];

function NavPanel({ open, onLinkClick }) {
  return (
    <div
      className={`nav-panel ${open ? "open" : "closed"} flex flex-col jc-c`}
    >
      <div className={'flex flex-col jc-sb'}>
        <NavItem
          heading={"Categories"}
          links={catLinks}
          onLinkClick={onLinkClick}
        ></NavItem>
        <NavItem
          heading={"Specials"}
          links={[]}
          onLinkClick={onLinkClick}
        ></NavItem>
        <NavItem
          heading={"Easyfix Maintenance Plan"}
          links={[]}
          onLinkClick={onLinkClick}
        ></NavItem>
        <NavItem
          heading={"Teljoy Business Systems"}
          links={[]}
          onLinkClick={onLinkClick}
        ></NavItem>
        <NavItem
          heading={"Blog"}
          links={[]}
          onLinkClick={onLinkClick}
        ></NavItem>
        <NavItem
          heading={"Contact Us"}
          links={[]}
          onLinkClick={onLinkClick}
        ></NavItem>
        <div className="nav-panel-share-icons flex flex-row jc-fs">
          <img src={twitterIcon} alt={"twitter"}></img>
          <img src={facebookIcon} alt={"facebook"}></img>
          <img src={instagramIcon} alt={"instagram"}></img>
        </div>
      </div>
    </div>
  );
}

export default NavPanel;
