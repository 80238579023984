import React, { useState, useContext, useEffect } from "react";
import ProductCard from "../../products/ProductCard";
import * as DUMMY_PRODUCTS from "../../../dummy/dummy.data";
import rtoIcon from "../../../assets/img/rto-green.svg";
import timeIcon from "../../../assets/img/time-green.svg";
import closeRoundIcon from "../../../assets/img/close-green.svg";
import toolsIcon from "../../../assets/img/tools-green.svg";
import pcIcon from "../../../assets/img/pc-green.svg";
import umbrellaIcon from "../../../assets/img/umbrella-green.svg";
import truckIcon from "../../../assets/img/truck-green.svg";
import starIcon from "../../../assets/img/star-icon.svg";
import heartIcon from "../../../assets/img/heart-icon.svg";
import shareIcon from "../../../assets/img/share-icon.svg";
import scaleIcon from "../../../assets/img/scale-icon.svg";

import addToCartIcon from "../../../assets/img/add-to-cart.svg";
import applyIcon from "../../../assets/img/apply.svg";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

import BannerInfo from "../../banners/BannerInfo";
import BannerExpandable from "../../banners/BannerExpandable";

import { Slider } from "@material-ui/core";
import ProductModal from "../ProductModal";
import AppContext from "../../../context/AppContext";
import { Link } from "react-router-dom";

import FooterContainer from "../../containers/FooterContainer";

import "./styles.css";
import { useParams } from "react-router";

const ProductInfo = ({ product }) => {
  const { category, subcategory } = useParams();
  const { state, setState } = useContext(AppContext);
  const [isModalActive, setIsModalActive] = useState(false);
  const [period, setPeriod] = useState(12);

  const scrollToFunction = () => {
    console.log("scrolling");
    document
      .getElementById("product-container")
      [`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  useEffect(() => {
    scrollToFunction();
  }, [product]);

  const getOtherProducts = () => {
    let cat;
    switch (category) {
      case "appliances":
        cat = DUMMY_PRODUCTS.APPLIANCES_DUMMY;
        break;
      case "electronics":
        cat = DUMMY_PRODUCTS.ELECTRONICS_DUMMY;
        break;
      case "furniture":
        cat = DUMMY_PRODUCTS.FURNITURE_DUMMY;
        break;
      case "gym-equipment":
        cat = DUMMY_PRODUCTS.GYM_DUMMY;
        break;
      default:
        cat = null;
    }
    let subcat = cat.find((item) => item.slug === subcategory).products;
    return subcat.filter((item) => item.slug !== product.slug);
  };

  const handleAddToCartClick = () => {
    const s = { ...state };
    if (s.cart.find((item) => product.slug === item.product.slug)) {
      s.cart.find((item) => product.slug === item.product.slug).qty++;
    } else {
      const obj = {
        name: "product",
        product: product,
        qty: 1,
      };
      if (state.nav.purchaseType === "rto") {
        obj.period = period;
      }
      s.cart.push(obj);
    }
    setState(s);
    setIsModalActive(true);
  };
  return (
    <div
      style={{ width: "100%", height: "100%", position: "relative" }}
      className="product-info"
      style={{ position: "relative" }}
    >
      <div
        id="product-container"
        style={{ width: "100%", height: "100%", overflowY: "auto" }}
      >
        <div className={`flex flex-col`}>
          <div
            className={"text-center container"}
            style={{ position: "relative" }}
          >
            <div
              className={"flex flex-col ai-c"}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                padding: "20px",
              }}
            >
              <img style={{ marginBottom: "10px" }} src={heartIcon}></img>
              <img style={{ marginBottom: "10px" }} src={shareIcon}></img>
              <img style={{ marginBottom: "10px" }} src={scaleIcon}></img>
            </div>
            <img
              style={{ height: "220px" }}
              src={product.img}
              alt={product.img}
            ></img>
          </div>
          <div className={`flex flex-row container`}>
            <div className={"text-thin text-calibre"}>
              <h2
                style={{ marginBottom: "6px" }}
                className={"color-text-primary"}
              >
                {product.name}
              </h2>
              <h4 className={"color-text-dim"}>{product.brand}</h4>
              <div
                style={{ margin: "10px 0" }}
                className={"flex flex-row jc-fs ai-c"}
              >
                <p style={{ marginRight: "8px" }}>4</p>
                <img
                  style={{ marginRight: "4px", marginBottom: "8px" }}
                  src={starIcon}
                ></img>
                <img
                  style={{ marginRight: "4px", marginBottom: "8px" }}
                  src={starIcon}
                ></img>
                <img
                  style={{ marginRight: "4px", marginBottom: "8px" }}
                  src={starIcon}
                ></img>
                <img style={{ marginBottom: "8px" }} src={starIcon}></img>
                <p style={{ marginLeft: "8px" }} className={"color-text-dim"}>
                  10 Reviews
                </p>
              </div>
              <div
                style={{ marginTop: "6px" }}
                className={`flex flex-row ai-c`}
              >
                <h5>In Stock</h5>
                {product.stock.map((s) => {
                  return (
                    <h5 style={{ margin: "0 6px" }} className={"text-bg"}>
                      {s}
                    </h5>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <BannerInfo shadowsTop>
          <h3
            className={"text-bg bg-primary"}
            style={{ margin: "10px 30px 0 30px" }}
          >
            Ownership Options
          </h3>
          <p className={"color-text-dim"} style={{ padding: "0 12px" }}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo.
          </p>
          <div className={"flex flex-row jc-se ai-c"}>
            <img src={rtoIcon} alt="rto"></img>
            <img src={timeIcon} alt="rto"></img>
            <img src={closeRoundIcon} alt="rto"></img>
            <img src={toolsIcon} alt="rto"></img>
            <img src={pcIcon} alt="rto"></img>
            <img src={umbrellaIcon} alt="rto"></img>
            <img src={truckIcon} alt="rto"></img>
          </div>
        </BannerInfo>
        <BannerExpandable shadowsTop>
          <h2 className={"color-text-primary"}>Description</h2>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo. Lorem
            ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
            eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
            diam voluptua. At vero eos et accusam et justo duo.
          </p>
        </BannerExpandable>
        <BannerExpandable shadowsTop>
          <h2 className={"color-text-primary"}>Product Information</h2>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell align="left">Brand</TableCell>
                  <TableCell align="right">{product.brand}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Doors</TableCell>
                  <TableCell align="right">{product.brand}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Model</TableCell>
                  <TableCell align="right">{product.brand}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Power</TableCell>
                  <TableCell align="right">{product.brand}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </BannerExpandable>
        <div className={"w-100 flex flex-col jc-fs container shadow-top"}>
          <h2
            className={"text-barlow color-text-primary"}
            style={{ marginLeft: "8px" }}
          >
            You Might Also Like
          </h2>
          <div className={"flex flex-row ai-c scroll-x"}>
            {getOtherProducts().map((item) => (
              <Link to={`/${category}/${subcategory}/${item.slug}`}>
                <ProductCard product={item}></ProductCard>
              </Link>
            ))}
          </div>
        </div>
        <FooterContainer></FooterContainer>
      </div>
      <div
        className={"product-info-bottom"}
        style={{ textAlign: "left", padding: "0 20px" }}
      >
        {state.nav.purchaseType === "rto" ? (
          <>
            <p className="color-text-dim">
              Rental Period{" "}
              <span style={{ color: "black", whitespace: "pre" }}>
                {period} Months
              </span>
            </p>
            <p style={{ color: "black", marginTop: "4px" }}></p>
            <Slider
              onChange={(e, v) => {
                setPeriod(v);
              }}
              defaultValue={12}
              step={12}
              min={12}
              max={72}
              valueLabelDisplay="auto"
              style={{
                color: "var(--color-primary)",
                width: "90%",
                marginLeft: "5%",
              }}
            />
          </>
        ) : null}

        <div
          className={"flex flex-row jc-sb ai-c"}
          style={{ padding: "0 12px" }}
        >
          <div>
            <p className="color-text-dim">Price</p>
            <p className="color-text-dark">
              {state.nav.purchaseType === "rto"
                ? `R${Math.round(product.price / period, 2)} pm`
                : `R${product.price}`}
            </p>
          </div>
          <div
            onClick={handleAddToCartClick}
            className="btn-round primary"
            style={{ margin: "0 6px" }}
          >
            <img src={addToCartIcon}></img>
            <p>Add to cart</p>
          </div>
          <div className="btn-round other">
            <img src={applyIcon}></img>
            <p>Apply</p>
          </div>
        </div>
      </div>

      <ProductModal
        product={product}
        visible={isModalActive}
        onOuterClick={() => setIsModalActive(false)}
      ></ProductModal>
    </div>
  );
};

export default ProductInfo;
