import React from "react";
import { Link, useParams } from "react-router-dom";
import { HEADINGS_CATEGORIES } from "../../../dummy/headings.data";
import * as DUMMY_DATA from "../../../dummy/dummy.data";
import BannerInfo from "../../banners/BannerInfo";
import FooterContainer from "../../containers/FooterContainer";
import Searchbar from "../../search/Searchbar";

import applianceTop from "../../../assets/img/appliances_top.png";
import delonghiImg from "../../../assets/img/delonghi.png";
import ProductCard from "../../products/ProductCard";
import chevronRight from "../../../assets/img/chevron-right.svg";
import chevronPurple from "../../../assets/img/chevron-purple.svg";
import CircleImg from "../../img/CircleImg";

function Category() {
  const { category } = useParams();

  const getHeading = () => {
    return HEADINGS_CATEGORIES.find((heading) => heading.slug === category)
      .name;
  };

  const getSubcategories = () => {
    const subcats = [];
    let cat;
    switch (category) {
      case "appliances":
        cat = DUMMY_DATA.APPLIANCES_DUMMY;
        break;
      case "electronics":
        cat = DUMMY_DATA.ELECTRONICS_DUMMY;
        break;
      case "furniture":
        cat = DUMMY_DATA.FURNITURE_DUMMY;
        break;
      case "gym-equipment":
        cat = DUMMY_DATA.GYM_DUMMY;
        break;
      default:
        cat = [];
        break;
    }
    for (let i = 0; i < cat.length; i++) {
      subcats.push({ name: cat[i].name, img: cat[i].img, slug: cat[i].slug });
    }
    return subcats;
  };

  const getProducts = () => {
    const products = [];
    let cat;
    switch (category) {
      case "appliances":
        cat = DUMMY_DATA.APPLIANCES_DUMMY;
        break;
      case "electronics":
        cat = DUMMY_DATA.ELECTRONICS_DUMMY;
        break;
      case "furniture":
        cat = DUMMY_DATA.FURNITURE_DUMMY;
        break;
      case "gym-equipment":
        cat = DUMMY_DATA.GYM_DUMMY;
        break;
      default:
        cat = [];
        break;
    }
    for (let i = 0; i < cat.length; i++) {
      products.push({ product: cat[i].products[0], subcategory: cat[i].slug });
      products.push({ product: cat[i].products[1], subcategory: cat[i].slug });
    }
    return products;
  };

  const getBrands = () => {
    const brands = [];
    let cat;
    switch (category) {
      case "appliances":
        cat = DUMMY_DATA.APPLIANCES_DUMMY;
        break;
      case "electronics":
        cat = DUMMY_DATA.ELECTRONICS_DUMMY;
        break;
      case "furniture":
        cat = DUMMY_DATA.FURNITURE_DUMMY;
        break;
      case "gym-equipment":
        cat = DUMMY_DATA.GYM_DUMMY;
        break;
      default:
        cat = [];
        break;
    }
    for (let i = 0; i < cat.length; i++) {
      brands.push(cat[i].brands[0]);
      brands.push(cat[i].brands[1]);
    }
    return brands;
  };

  return (
    <div className={"flex flex-col jc-fs ai-c"}>
      <Searchbar></Searchbar>
      <h1
        className={"text-barlow color-text-primary"}
        style={{ marginBottom: "20px" }}
      >
        {getHeading()}
      </h1>
      <img
        style={{
          width: "14px",
          marginBottom: "20px",
          transform: "rotate(-90deg)",
        }}
        src={chevronPurple}
      ></img>
      <div className={"w-100 shadow-btm"}>
        <img className={"w-100"} src={applianceTop}></img>
        <div className={"w-100 flex flex-col jc-fs container"}>
          <h2 className={"text-barlow color-text-primary"}>
            Popular {getHeading()} Brands
          </h2>
          <div className={"flex flex-row ai-c"}>
            <img
              style={{
                width: "20px",
                margin: "0",
                transform: "rotate(180deg)",
              }}
              src={chevronRight}
            ></img>
            <div
              className={"flex flex-row ai-c scroll-x"}
              style={{ padding: "10px 5px", minWidth: "calc(100% - 40px)" }}
            >
              {getBrands().map((item) => (
                <img className={"brand-img"} src={item.img}></img>
              ))}
            </div>
            <img
              style={{ width: "20px", margin: "0 5px" }}
              src={chevronRight}
            ></img>
          </div>
        </div>
      </div>

      <div className={"w-100 flex flex-col jc-fs container"}>
        <h2 className={"text-barlow color-text-primary"}>
          Popular {getHeading()}
        </h2>
        <div className={"flex flex-row ai-c scroll-x"}>
          {getProducts().map((item) => (
            <Link to={`${category}/${item.subcategory}/${item.product.slug}`}>
              <ProductCard product={item.product}></ProductCard>
            </Link>
          ))}
        </div>
      </div>
      <div className={"w-100 flex flex-row jc-fs ai-c container"}>
        {getSubcategories().map((item) => (
            <Link to={`/${category}/${item.slug}`}>
                <CircleImg src={item.img} text={item.name}></CircleImg>
            </Link>
        ))}
      </div>
      <img className={"w-100"} src={delonghiImg}></img>
      <div className={"w-100 flex flex-col jc-fs"} style={{ padding: "20px" }}>
        <h2
          className={"text-barlow color-text-primary"}
          style={{ marginBottom: "20px" }}
        >
          Shop {getHeading()}
        </h2>
        <p className={"text-calibre color-text-dim"}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum
          est ultricies integer quis. Iaculis urna id volutpat lacus laoreet.
          Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci
          dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce
          ut placerat orci nulla. Tincidunt ornare massa eget egestas purus
          viverra accumsan in nisl. Tempor id eu nisl nunc mi ipsum faucibus.
          Fusce id velit ut tortor pretium. Massa ultricies mi quis hendrerit
          dolor magna eget. Nullam eget felis eget nunc lobortis. Faucibus
          ornare suspendisse sed nisi. Sagittis eu volutpat odio facilisis
          mauris sit amet massa. Erat velit scelerisque in dictum non
          consectetur a erat. Amet nulla facilisi morbi tempus iaculis urna.
          Egestas purus viverra¸
        </p>
      </div>

      <FooterContainer></FooterContainer>
    </div>
  );
}

export default Category;
