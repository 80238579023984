import React from "react";
import ProductInfo from "../../products/ProductInfo";
import { useParams } from "react-router-dom";
import * as DUMMY_PRODUCTS from "../../../dummy/dummy.data";

function Product() {
  const { category, subcategory, product_slug } = useParams();

  const getProduct = () => {
    let cat;
    switch (category) {
      case "appliances":
        cat = DUMMY_PRODUCTS.APPLIANCES_DUMMY;
        break;
      case "electronics":
        cat = DUMMY_PRODUCTS.ELECTRONICS_DUMMY;
        break;
      case "furniture":
        cat = DUMMY_PRODUCTS.FURNITURE_DUMMY;
        break;
      case "gym-equipment":
        cat = DUMMY_PRODUCTS.GYM_DUMMY;
        break;
      default:
        cat = null;
    }
    let subcat = cat.find((item) => item.slug === subcategory).products;
    return subcat.find((item) => item.slug === product_slug);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ProductInfo product={getProduct()}></ProductInfo>
    </div>
  );
}

export default Product;
