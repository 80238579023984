import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";

import successIcon from "../../../assets/img/success.svg";
import AppContext from "../../../context/AppContext";

function ProductModal({ product, visible, onOuterClick }) {
  const { state, setState } = useContext(AppContext);
  const prod = state.cart.find((item) => item.product.slug === product.slug);
  const history = useHistory();
  const incQty = () => {
    const s = { ...state };
    s.cart.find((item) => item.product.slug === product.slug).qty++;
    setState(s);
  };
  const decQty = () => {
    const s = { ...state };
    s.cart.find((item) => item.product.slug === product.slug).qty--;
    setState(s);
  };
  return (
    <div className={`product-modal ${visible ? "open" : ""} flex jc-c ai-c`}>
      <div className={"product-modal-bg"} onClick={onOuterClick}></div>
      <div className="product-modal-content">
        <img src={successIcon} style={{ width: "32px", height: "32px" }}></img>
        <p>{product.name} has been added to your cart</p>
        <img style={{ height: "50%" }} src={product.img}></img>
        <div className={"flex flex-row ai-c"}>
          <h6 onClick={decQty} className={"text-circle"}>
            -
          </h6>
          <h4>
            {state.cart.length > 0
              ? state.cart.find((item) => item.product.slug === product.slug)
                ? state.cart.find((item) => item.product.slug === product.slug)
                    .qty
                : 1
              : 1}
          </h4>
          <h6 onClick={incQty} className={"text-circle"}>
            +
          </h6>
        </div>
        <div className={"flex flex-row jc-se ai-c"} style={{ width: "100%" }}>
          <div className={"flex flex-col"}>
            <p>Rental Period & Price</p>
            <p>{prod && prod.period ? `${prod.period} months` : ""}</p>
            <p>
              R
              {prod && prod.period
                ? `${Math.round(product.price / prod.period, 2)} per month`
                : product.price}
            </p>
          </div>
          <div className={"flex flex-col"}>
            <div
              onClick={() => history.replace("/cart")}
              className={"modal-btn fill"}
            >
              <h4>Go to</h4>
            </div>
            <div onClick={onOuterClick} className={"modal-btn ghost"}>
              <h4>Continue</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductModal;
