import React, { useContext } from 'react';
import AppContext from '../../../context/AppContext';
import CartProduct from '../../cart/CartProduct';
import FooterContainer from '../../containers/FooterContainer';
import './styles.css';

function Cart() {
    const { state } = useContext(AppContext);

    const getCartTotal = () => {
        let total = 0;
        for (let i = 0; i < state.cart.length; i++) {
            if (state.cart[i].period) {
                total += Math.round(state.cart[i].product.price / state.cart[i].period, 2);
            } else {
                total += state.cart[i].product.price;
            }

        }
        return total;
    }

    return (
        <div className='flex flex-col jc-fs ai-c' style={{width: '100%', fontFamily: 'Calibre', paddingTop: '10px'}}>
            <h1 className="text-center color-text-other mv">
                Your Cart
            </h1>
            <div className="cart-items" style={{width: '100%'}}>
                {state.cart.length > 0 ? state.cart.map(product => {
                    return <CartProduct product={product}></CartProduct>
                }) : <h3>No items in cart</h3>}
            </div>
            <div className={'flex flex-col jc-fs ai-fs cart-total'}>
                <h5>Cart Total</h5>
                <h3>R {getCartTotal()} per month</h3>
            </div>
            <div className={'flex flex-col jc-c ai-c'} style={{padding: '8px', marginBottom: '40px'}}>
                <p style={{fontSize: '12px', marginBottom: '10px'}} className={'color-text-dim text-calibre'}>Placing an item in your cart does not reserve it for purchase</p>
                <h2 className={'cart-btn'}>Checkout</h2>
            </div>
            <FooterContainer></FooterContainer>
        </div>
    )
}

export default Cart
