import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import chevronRight from "../../../assets/img/chevron-right.svg";
import AppContext from "../../../context/AppContext";
import "./styles.css";

const NavItemSplitPanel = ({ name, currActive, activeChange, links, onLinkClick }) => {
  const { state, setState } = useContext(AppContext);
  const [open, setIsOpen] = useState(false);
  const handleOpenClick = () => {
    !open ? activeChange(name) : activeChange(null);
    setIsOpen(!open);
  }

  return (
    <div className={`nav-item-sp flex flex-row ${currActive && name !== currActive ? 'inactive' : name === currActive ? 'active': ''}`}>
      <div className="w-50 flex flex-row jc-fs">
        <h3 onClick={() => handleOpenClick()}>{links.name}</h3>
      </div>
      <div
        className={`w-50 flex flex-col jc-fs nav-item-link-menu ${
          open ? "active" : ""
        }`}
      >
        {links.children.map((link) => {
          return (
            <Link
              onClick={() => {setIsOpen(false); onLinkClick(name, link.name)}}
              className={"nav-item-link color-text-primary"}
              key={link.name}
              to={`${links.parentUrl}${link.to}`}
            >
              <h3 className={"nav-item-link color-text-primary"}>
                {link.name}
              </h3>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

function NavItem({ heading, links, onLinkClick }) {
  const { state, setState } = useContext(AppContext);
  const [open, setIsOpen] = useState(false);
  const [activeName, setActiveName] = useState(null);

  const handleLinkClick = (mainHeading, subHeading) => {
    setIsOpen(false);
    setActiveName(null);
    onLinkClick();
  }

  return (
    <div className="nav-item">
      <div
        onClick={() => setIsOpen(!open)}
        className={"flex flex-row jc-fs ai-c"}
      >
        <h3 className={"nav-item-heading"}>{heading}</h3>
        {heading === "Categories" ? (
          <img
            src={chevronRight}
            style={{ height: "12px", marginBottom: "22px", marginLeft: "14px" }}
            className={`n-h ${open ? "active" : ""}`}
          ></img>
        ) : null}
      </div>
      <div className={`nav-item-panel ${open ? 'active' : ''}`}>
        {links.map((link) => {
              return (
                <NavItemSplitPanel
                  name={link.name}
                  currActive={activeName}
                  activeChange={(name) => setActiveName(name)}
                  key={link.name}
                  onLinkClick={handleLinkClick}
                  links={link}
                ></NavItemSplitPanel>
              );
            })}
      </div>
    </div>
  );
}

export default NavItem;
