export const HEADINGS_CATEGORIES = [
  {
    slug: "appliances",
    name: "Appliances",
    sublinks: [
      { slug: "fridges-ice-makers", name: "Fridges & Ice Makers" },
      { slug: "dishwashers", name: "Dishwashers" },
    ],
  },
  {
    slug: "electronics",
    name: "Electronics",
    sublinks: [
      {
        slug: "tv",
        name: "TVs",
      },
    ],
  },
  {
    name: "Furniture",
    slug: "furniture",
    sublinks: [
      {
        slug: "beds-bedroom-suites",
        name: "Beds & Bedrooms",
      },
    ],
  },
  {
    name: "Gym",
    slug: "gym-equipment",
    sublinks: [
      {
        slug: "treadmills",
        name: "Treadmills",
      },
    ],
  },
];
