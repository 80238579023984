import React from 'react'
import Navbar from '../../nav/Navbar'

function NavContainer() {
    return (
        <div id="nav-container">
            <Navbar></Navbar>
        </div>
    )
}

export default NavContainer
