import React from "react";
import "./styles.css";

function CircleImg({ src, text }) {
  return (
    <div className={"circle-img"}>
      <img src={src} alt={"alt-tag"}></img>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 153.018 52.685"
        className={'circle-img-line'}
      >
        <path
          id="Path_674"
          data-name="Path 674"
          d="M924.8,995s21.946,25.6,60.813,24.955S1045.383,995,1045.383,995"
          transform="translate(-908.582 -978.782)"
          fill="none"
          stroke="#581dff"
          stroke-linecap="round"
          stroke-width="23"
        />
        <text fill={'#fff'} fontSize={'18px'} dy={5}>
          <textPath style={{alignSelf: 'center'}} textAnchor={'middle'} startOffset={'50%'} alignmentBaseline={'center'} xlinkHref={'#Path_674'}>{text}</textPath>
        </text>
      </svg>
    </div>
  );
}

export default CircleImg;
