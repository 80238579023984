import React, { useState } from "react";
import searchIcon from "../../../assets/img/search-icon.png";
import "./styles.css";

function Searchbar() {
  const [searchStr, setSearchStr] = useState("");

  const submit = () => {
    return searchStr;
  }

  return (
    <div id="search-main">
      <div className={"searchbar flex flex-row jc-c ai-c"}>
        <input
          type="text"
          name="search_str"
          onChange={(e) => {
            setSearchStr(e.target.value);
          }}
          placeholder={"Search"}
        ></input>
        <div onClick={submit} className={"searchbar-icon flex jc-c ai-c"}>
          <img src={searchIcon} alt={"search-icon"}></img>
        </div>
      </div>
    </div>
  );
}

export default Searchbar;
