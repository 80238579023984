import fridgeOne from "../assets/img/defy_lrg.png";
import fridgeTwo from "../assets/img/fridge-two.png";
import fridgeThree from "../assets/img/defy_1_door.png";
import fridgeFour from "../assets/img/smeg_fridge.png";

import dwOne from "../assets/img/dw-one.png";
import dwTwo from "../assets/img/dw-two.png";
import dwThree from "../assets/img/dw-two.png";

import tvOne from "../assets/img/tv-1.png";
import tvTwo from "../assets/img/tv-2.png";
import tvThree from "../assets/img/tv-3.png";

import bedOne from '../assets/img/bed-1.png';
import bedTwo from '../assets/img/bed-two.png';

import treadmillOne from '../assets/img/treadmill-one.png';
import treadmillTwo from '../assets/img/treadmill-two.png';
import treadmillThree from '../assets/img/treadmill-three.png';

import fridgeIcon from '../assets/img/fridges-icon.png'
import dishIcon from '../assets/img/dish-icon.png'
import tvIcon from '../assets/img/tv-icon.png'
import bedIcon from '../assets/img/bed-icon.png'
import tmIcon from '../assets/img/tm-icon.png'

import defyIcon from "../assets/img/defy-brand.png";
import hisenseIcon from "../assets/img/hisense-brand.png";
import boschIcon from "../assets/img/bosch-brand.png";
import smegIcon from "../assets/img/smeg-brand.png";
import samsungIcon from "../assets/img/samsung-brand.png";
import skyworthIcon from "../assets/img/skyworth-brand.png";
import lalaIcon from '../assets/img/lala-logo.jpeg';
import graceIcon from '../assets/img/grace-logo.png';
import trojanIcon from '../assets/img/trojan-logo.png';
import technoIcon from '../assets/img/technogym-logo.png';
import maxfitIcon from '../assets/img/maxfit-logo.jpg';

export const APPLIANCES_DUMMY = [
  {
    slug: "fridges-ice-makers",
    name: 'Fridges',
    img: fridgeIcon,
    brands: [
      {
        name: "Defy",
        img: defyIcon,
      },
      {
        name: "Hisense",
        img: hisenseIcon,
      },
      {
        name: "Bosch",
        img: boschIcon,
      },
      {
        name: "Smeg",
        img: smegIcon,
      },
    ],
    products: [
      {
        name: "Defy 195l Grey",
        slug: "defy-195l-grey",
        img: fridgeOne,
        brand: "Defy",
        stock: ["DBN", "CPT"],
        price: "5000",
        rating: {
          score: "4",
          count: "10",
        },
      },
      {
        name: "Defy 348l Metallic",
        slug: "defy-348l-metallic",
        img: fridgeTwo,
        brand: "Defy",
        stock: ["JHB", "DBN", "CPT"],
        price: "8000",
        rating: {
          score: "4",
          count: "10",
        },
      },
      {
        name: "Hisense 91l Metallic Bar",
        slug: "hisense-91l-metallic-bar",
        img: fridgeThree,
        brand: "Hisense",
        stock: ["JHB", "CPT"],
        price: "3000",
        rating: {
          score: "4",
          count: "10",
        },
      },
      {
        name: "Smeg 120l Metallic Bar",
        slug: "smeg-120l-metallic-bar",
        img: fridgeFour,
        brand: "Smeg",
        stock: ["JHB"],
        price: "7000",
        rating: {
          score: "4",
          count: "10",
        },
      },
    ],
  },
  {
    slug: "dishwashers",
    name: 'Dishwashers',
    img: dishIcon,
    brands: [
      {
        name: "Bosch",
        img: boschIcon,
      },
      {
        name: "Defy",
        img: defyIcon,
      },
      {
        name: "Hisense",
        img: hisenseIcon,
      },
    ],
    products: [
      {
        name: "Defy 13 Place Dishwasher",
        slug: "defy-13-place",
        img: dwOne,
        brand: "Defy",
        stock: ["DBN", "CPT"],
        price: "3500",
        rating: {
          score: "4",
          count: "10",
        },
      },
      {
        name: "Bosch 16 Place Dishwasher",
        slug: "bosch-16-place",
        img: dwTwo,
        brand: "Defy",
        stock: ["JHB", "CPT"],
        price: "5000",
        rating: {
          score: "4",
          count: "10",
        },
      },
      {
        name: "Hisense 13 Place Dishwasher",
        slug: "hisense-13-place",
        img: dwThree,
        brand: "Defy",
        stock: ["CPT"],
        price: "4500",
        rating: {
          score: "4",
          count: "10",
        },
      },
    ],
  },
];

export const ELECTRONICS_DUMMY = [
  {
    slug: "tv",
    name: 'TVs',
    img: tvIcon,
    brands: [
      {
        name: "Samsung",
        img: samsungIcon,
      },
      {
        name: "Skyworth",
        img: skyworthIcon,
      },
      {
        name: "Hisense",
        img: hisenseIcon,
      },
    ],
    products: [
      {
        name: '55" Samsung Smart TV',
        slug: "55-samsung-smart-tv",
        img: tvOne,
        brand: "Samsung",
        stock: ["JHB", "CPT"],
        price: "12000",
        rating: {
          score: "4",
          count: "10",
        },
      },
      {
        name: '77" Skyworth Smart TV',
        slug: "77-skyworth-smart-tv",
        img: tvTwo,
        brand: "Skyworth",
        stock: ["JHB", "DBN", "CPT"],
        price: "22000",
        rating: {
          score: "4",
          count: "10",
        },
      },
      {
        name: '50" Hisense Smart TV',
        slug: "50-hisense-smart-tv",
        img: tvThree,
        brand: "Hisense",
        stock: ["JHB", "CPT"],
        price: "9000",
        rating: {
          score: "4",
          count: "10",
        },
      },
    ],
  },
];

export const FURNITURE_DUMMY = [
  {
    slug: "beds-bedroom-suites",
    name: 'Beds & Bedroom',
    img: bedIcon,
    brands: [
      {
        name: "Lala",
        img: lalaIcon,
      },
      {
        name: "Grace Albin",
        img: graceIcon,
      },
    ],
    products: [
      {
        name: "Lala Milan Double Bed",
        slug: "lala-double-bed",
        img: bedOne,
        brand: "Lala",
        stock: ["JHB", "DBN", "CPT"],
        price: "9000",
        rating: {
          score: "4",
          count: "10",
        },
      },
      {
        name: "Grace Albin Upholstered Headboard",
        slug: "grace-albin-upholstered-headboard",
        img: bedTwo,
        brand: "Grace Albin",
        stock: ["JHB", "DBN", "CPT"],
        price: "4000",
        rating: {
          score: "4",
          count: "10",
        },
      },
    ],
  },
];

export const GYM_DUMMY = [
  {
    slug: "treadmills",
    name: 'Treadmills',
    img: tmIcon,
    brands: [
      {
        name: "Trojan",
        img: trojanIcon,
      },
      {
        name: "Technogym",
        img: technoIcon,
      },
      {
        name: "Maxfit",
        img: maxfitIcon,
      },
    ],
    products: [
      {
        name: "Technogym Treadmill",
        slug: "technogym-treadmill",
        img: treadmillTwo,
        brand: "Technogym",
        stock: ["JHB", "DBN", "CPT"],
        price: "12000",
        rating: {
          score: "4",
          count: "10",
        },
      },
      {
        name: "Maxfit Treadmill",
        slug: "maxfit-treadmill",
        img: treadmillThree,
        brand: "Maxfit",
        stock: ["JHB", "CPT"],
        price: "11000",
        rating: {
          score: "4",
          count: "10",
        },
      },
      {
        name: "Trojan TR300 Treadmill",
        slug: "trojan-tr300-treadmill",
        img: treadmillOne,
        brand: "Trojan",
        stock: ["JHB", "DBN"],
        price: "13000",
        rating: {
          score: "4",
          count: "10",
        },
      },
    ],
  },
];
